import { createTheme, responsiveFontSizes } from '@mui/material';

export default responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                main: '#fdc500',
            },
            secondary: {
                main: '#6e10b8',
                light: '#8c52ff',
            },
            background: {
                default: '#000000',
            },
            text: {
                primary: '#ffffff',
            },
            action: {
                disabledBackground: '#1d1d1d',
                disabled: '#000000',
            },
        },
        typography: {
            fontFamily: [
                'Montserrat Classic',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
            ].join(','),
            fontSize: 12,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& label': {
                            color: '#ffffff',
                        },
                        '&:hover label': {
                            color: '#fdc500',
                        },
                        '& label.Mui-focused': {
                            color: '#fdc500',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#ffffff',
                            },
                            '&:hover fieldset': {
                                borderColor: '#fdc500',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#fdc500',
                            },
                        },
                    },
                },
            },
        },
    })
);
