import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import classes from './classes';

const LoadingScreen = () => {
    return (
        <Box css={classes.container}>
            <LinearProgress color='primary' css={classes.loadingBar} />
        </Box>
    );
};

export default LoadingScreen;
