import { effect } from '@preact/signals-core';
import { useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import theme from '../../theme';
import { backgroundState, logoState } from '../../utils/signals/NavBar';

const useNavBar = (onPathnameChange) => {
    const controls = useAnimation();
    const logoControls = useAnimation();

    const backgroundVariants = {
        black: {
            backgroundColor: theme.palette.background.default,
            transition: { duration: 0.5 },
        },
        white: {
            backgroundColor: 'rgba(0,0,0, 0.4)',
            transition: { duration: 0.5 },
        },
    };
    const logoVariants = {
        visible: {
            display: 'block',
            opacity: 1,
            transition: { duration: 0.5 },
        },
        invisible: {
            opacity: 0,
            transition: { duration: 0.5 },
            transitionEnd: {
                display: 'none',
            },
        },
    };

    const [navOptions, setNavOptions] = useState([
        { name: 'Home', href: '/', active: false },
        { name: 'About Us', href: '/about', active: false },
        {
            name: 'Contact',
            href: '/contact',
            active: false,
        },
    ]);

    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = ({ currentTarget }) => setAnchorEl(currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleClick = (i) => (e) => {
        setNavOptions((navOptions) =>
            navOptions?.map((opt, mapI) => ({
                ...opt,
                active: i === -1 ? false : mapI === i,
            }))
        );

        handleMenuClose();
    };

    useEffect(() => {
        setNavOptions((navOptions) =>
            navOptions?.map((opt) => ({
                ...opt,
                active: opt?.href === window.location.pathname,
            }))
        );

        onPathnameChange(window.location.pathname);

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [window.location.pathname]);

    effect(() => controls.start(backgroundState.value));
    effect(() => logoControls.start(logoState.value));

    return {
        anchorEl,
        navOptions,
        isMenuOpen,
        handleClick,
        handleMenuOpen,
        handleMenuClose,
        controls,
        backgroundVariants,
        logoControls,
        logoVariants,
        isNavbarSolid: backgroundState.value === 'black',
    };
};

export default useNavBar;
