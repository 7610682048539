import { css } from '@emotion/react';

const notFoundContainer = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 500,
});

export default { notFoundContainer };
