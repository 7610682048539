import { css } from '@emotion/react';

export default {
    container: css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 700,
    }),
    loadingBar: css({
        width: '50%',
    }),
};
