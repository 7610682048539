import { css } from '@emotion/react';
import theme from '../../theme';

export default {
    navbar: css({
        position: 'fixed',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 5vw',
        width: `calc(100% - 10vw)`,
        height: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        fontSize: 16,
        zIndex: 100,
    }),
    logoBox: {
        height: 48,
        display: 'flex',
        alignItems: 'center',
    },
    fraalTechLogo: css({
        maxHeight: '2em',
    }),
    linkList: css({
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(4),
        svg: {
            fontSize: 32,
        },
    }),
    navStack: css({
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    }),
    navMenu: (isNavbarSolid = true) =>
        css({
            '& .MuiMenu-list': {
                padding: 0,
            },
            '& .MuiPaper-root': {
                backgroundColor: isNavbarSolid
                    ? theme.palette.background.default
                    : 'rgba(0,0,0,0)',
                boxShadow: isNavbarSolid ? 'inherit' : 'none',
            },
        }),
    navItem: (active = false, isNavbarSolid = true) =>
        css({
            backgroundColor: isNavbarSolid
                ? theme.palette.background.default
                : 'rgba(0,0,0,0)',
            color: theme.palette.primary.main,
            fontWeight: active ? 700 : 400,
            textDecoration: active ? 'underline' : 'inherit',
            '&:hover': {
                backgroundColor: isNavbarSolid
                    ? theme.palette.primary.main
                    : 'rgba(0,0,0,0)',
                color: isNavbarSolid
                    ? theme.palette.background.default
                    : theme.palette.secondary.light,
                textDecoration: active ? 'underline black' : 'inherit',
            },
        }),
};
