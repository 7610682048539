import { css } from '@emotion/react';
import theme from './theme';

export default {
    container: css({
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'scroll',
        height: '100%',
        maxHeight: '100vh',
    }),
    bgImg: (currPathname) =>
        css({
            position: 'absolute',
            maxWidth: '100vw',
            objectFit: 'contain',
            height: '100%',
            top: 0,
            ...(currPathname === '/about' ? { left: 0 } : { right: 0 }),
            zIndex: 1,
            [theme.breakpoints.down('sm')]: {
                maxHeight: '100vh',
                width: '100%',
                objectFit: 'cover',
            },
        }),
};
