import { Typography } from '@mui/material';
import React from 'react';
import classes from './classes';

const NotFound = () => {
    return (
        <section css={classes.notFoundContainer}>
            <Typography variant='h2'>Page Not Found.</Typography>
        </section>
    );
};

export default NotFound;
