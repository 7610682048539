import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './src/App';
import './dist/index.css';

const container = document.getElementById('app');

const root = createRoot(container);

root.render(<App />);
