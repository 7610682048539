import { ThemeProvider } from '@mui/material';
import React, { lazy, Suspense, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import classes from './classes';
import LoadingScreen from './components/LoadingScreen';
import NavBar from './components/NavBar';
import NotFound from './components/NotFound';
import theme from './theme';

import homePageImg from '../dist/homepage.jpg';
import aboutPageImg from '../dist/aboutpage.jpg';

const AboutPage = lazy(() => import('./components/AboutPage'));
const HomePage = lazy(() => import('./components/HomePage'));
const ContactPage = lazy(() => import('./components/ContactPage'));

const bgImgOptions = {
    '/': homePageImg,
    '/about': aboutPageImg,
    '/contact': homePageImg,
};

const App = () => {
    const [currentPathname, setCurrentPathname] = useState('/');
    const [bgImg, setBgImg] = useState(homePageImg);
    const onPathnameChange = (pathname) => {
        setCurrentPathname(pathname);
        setBgImg(bgImgOptions[pathname]);
    };

    return (
        <div css={classes.container}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <NavBar onPathnameChange={onPathnameChange} />
                    <Suspense fallback={<LoadingScreen />}>
                        <Routes>
                            <Route path='/' element={<HomePage />} />
                            <Route path='/about' element={<AboutPage />} />
                            <Route path='/contact' element={<ContactPage />} />
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </ThemeProvider>
            <img src={bgImg} css={classes.bgImg(currentPathname)} key={bgImg} />
        </div>
    );
};

export default App;
