import { Box, IconButton, Link, Menu, MenuItem, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import classes from './classes';
import useNavBar from './useNavBar';
import { motion } from 'framer-motion';
import { Menu as MenuIcon, Close as CloseMenuIcon } from '@mui/icons-material';
import logoImg from '../../../dist/logo.png';

const NavBar = ({ onPathnameChange }) => {
    const {
        anchorEl,
        navOptions,
        isMenuOpen,
        handleClick,
        handleMenuOpen,
        handleMenuClose,
        controls,
        backgroundVariants,
        logoControls,
        logoVariants,
        isNavbarSolid,
    } = useNavBar(onPathnameChange);

    return (
        <motion.header
            css={classes.navbar}
            animate={controls}
            variants={backgroundVariants}
        >
            <Link
                css={classes.logoBox}
                component={RouterLink}
                to={'/'}
                underline='none'
                onClick={handleClick(-1)}
            >
                <motion.img
                    src={logoImg}
                    css={classes.fraalTechLogo}
                    animate={logoControls}
                    variants={logoVariants}
                />
            </Link>
            {navOptions?.length > 0 && (
                <Box css={classes.linkList}>
                    <IconButton color='primary' onClick={handleMenuOpen}>
                        {isMenuOpen ? <CloseMenuIcon /> : <MenuIcon />}
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        open={isMenuOpen}
                        onClose={handleMenuClose}
                        css={classes.navMenu(isNavbarSolid)}
                    >
                        <Stack css={classes.navStack}>
                            {navOptions.map(({ name, href, active }, i) => (
                                <MenuItem
                                    key={`${name}${href}`}
                                    css={classes.navItem(active, isNavbarSolid)}
                                    onClick={handleClick(i)}
                                    to={href}
                                    component={RouterLink}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Stack>
                    </Menu>
                </Box>
            )}
        </motion.header>
    );
};

export default NavBar;
